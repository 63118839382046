import { THEME_DEFAULT_CONFIG } from "@/tailwind/theme-default-config";
import { Theme } from "@/tailwind/themes";

// On page load or when changing themes, best to add inline in `head` to avoid FOUC
const defaultTheme =
  localStorage.getItem(THEME_DEFAULT_CONFIG.themeStorageKey as Theme) ?? THEME_DEFAULT_CONFIG.theme;
const defaultThemeVariant =
  localStorage.getItem(THEME_DEFAULT_CONFIG.themeVariantStorageKey as Theme) ??
  THEME_DEFAULT_CONFIG.themeVariant;
const defaultThemeRadius =
  localStorage.getItem(THEME_DEFAULT_CONFIG.themeRadiusStorageKey as Theme) ??
  THEME_DEFAULT_CONFIG.themeRadius;

if (
  defaultTheme === "dark" ||
  (defaultTheme === "system" && window.matchMedia("(prefers-color-scheme: dark)").matches)
) {
  document.documentElement.classList.add("dark");
}

document.documentElement.dataset[THEME_DEFAULT_CONFIG.themeVariantStorageKey] = defaultThemeVariant;
document.documentElement.dataset[THEME_DEFAULT_CONFIG.themeRadiusStorageKey] = defaultThemeRadius;
